.section-aboutus {
	@include py-100;
	@include mtn-8;

	.title {
		text-transform: uppercase;

		&.main {
			@include underline-txt-start($title-main, 6px, 96px, 16px);

			@include media-breakpoint-down(xs) {
				@include underline-txt($title-main, 6px, 96px, 8px);
				text-align: center;
			}
		}

		&.sec {
			@include underline-txt-start($title-main, 6px, 96px, 8px);

			@include media-breakpoint-down(xs) {
				@include underline-txt($title-main, 6px, 96px, 8px);
				text-align: center;
			}
		}
	}

	.discover {
		@include mb-72;

		.txt {
			max-width: 80%;

			@include media-breakpoint-down(xs) {
				max-width: 100%;
				text-align: center;
			}
		}
	}

	.features {
		column-gap: 1rem;
		.feature {
			background-color: $white;
			padding: 1rem;
			box-shadow: 0px 2px 10px 0px rgba(101, 101, 101, 0.5);
			-webkit-box-shadow: 0px 2px 10px 0px rgba(101, 101, 101, 0.5);
			-moz-box-shadow: 0px 2px 10px 0px rgba(101, 101, 101, 0.5);
			h5{
				font-size: 1.5rem !important;
			}
		}
	}

	.values {
		img {
			&.png {
				padding-right: 2rem;

				@include media-breakpoint-down(xs) {
					padding-right: 0;
				}
			}
		}

		.txt {
			max-width: 87%;

			@include media-breakpoint-down(xs) {
				max-width: 100%;
				text-align: center;
			}
		}
	}
}
