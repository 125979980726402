.section-form {
	@include py-38;

	.content-al {
		@include media-breakpoint-down(xs) {
			@include mb-16;
		}

		.title {
			@include txt-font-36($white, $fw-black);
			margin-bottom: 1rem;
			text-transform: uppercase;
		}

		img {
			&.svg {
				float: left;
			}
		}

		.txt-p {
			@include txt-font-16($white, $fw-medium);
			margin: 0 0 1.2rem 2rem;
			max-width: 74%;

			@include media-breakpoint-down(xs) { max-width: 100%;	}
		}
	}

	.contact-form {
		backdrop-filter: blur(4px);
		background: $glass-bkg;
		border: 1px solid $glass-border;
		border-radius: 9px;
		margin: 0 1.5rem;
		min-height: 454px;
		padding: 1.7rem 4rem;

		@include media-breakpoint-down(xs) {
			margin: 0;
			padding: 1.7rem 2rem;
		}

		.form-help {
			@include txt-font-16($white, $fw-extrabold);
			text-align: center;
		}

		.form-field { position: relative; }

		.form-content {
			padding: 1rem 0;
			position: relative;
		}

		// input:not([type="radio"]):not([type="checkbox"]):placeholder-shown:not(:focus) + label,
		// select.invalid:not(:focus) + label,
		// select:invalid:not(:focus) + label,
		// textarea:placeholder-shown:not(:focus) + label {
		// 	@include txt-font-12($_form-color, , 12px, 400);
		// }

		input:not([type="radio"]):not([type="checkbox"]):not(:focus) + label,
		select:invalid:not(:focus) + label,
		textarea:not(:focus) + label {
			@include txt-font-16($white, $fw-bold);
		}

		.sec-contacto input:not([type="radio"]):not([type="checkbox"]),
		.sec-contacto select,
		.sec-contacto textarea {
			@include txt-font-16($white, $fw-bold); border-width: 1px; height: auto;
		}

		input:not([type="radio"]):not(:focus) + label,
		// input:placeholder-shown,
		input:not([type="checkbox"]):not(:focus) + label,
		// input:placeholder-shown,
		select:invalid:not(:focus) + label,
		// textarea:placeholder-shown,
		textarea:not(:focus) + label {
			@include txt-font-16($white, $fw-bold);
			text-transform: capitalize;
		}

		input::placeholder,
		textarea::placeholder {
			color: transparent;
		}

		// input:placeholder-shown,
		// textarea:placeholder-shown {
		// 	@include txt-font-12($_form-color, , 10px, 400);
		// 	color: transparent;
		// 	opacity: 1;
		// }

		input:not([type=radio]):not([type=checkbox])+label,
		select+label,
		textarea+label {
			color: $white;
			display: block;
			font-size: 0.64rem;
			left: 0;
			position: absolute;
			top: 0.6rem;
			transition: 0.2s ease-in-out;
		}

		input:not([type=radio]):not([type=checkbox]),
		select,
		textarea {
			background: 0 0;
			border: 0;
			border-bottom: 2px solid $white;
			color: $white;
			font-size: 1rem;
			// height: 2rem;
			line-height: 1.1rem;
			padding: 0.5rem 0;
			transition: 0.2s ease-in-out;
			width: 100%;
		}

		input:not([type=radio]):not([type=checkbox]):focus,
		select:focus,
		textarea:focus {
			// border-bottom: 2px solid $white;
			box-shadow: none;
			padding-top: 1rem;
		}

		label { text-transform: capitalize; }

		textarea {
			border: 2px solid $white;
			border-radius: 5px;
			padding: 0.5rem;

			&:focus {
				// border-bottom: 2px solid $white;
				box-shadow: none;
				padding-top: 0.5rem;
			}
		}

		textarea+label {
			font-size: 1rem;
			position: absolute;
			top: -0.6rem;
		}

		// .button {
		// 	@include txt-font-18($white, $fw-bold);
		// 	border: 0;
		// 	cursor: pointer;
		// 	display: inline-block;
		// 	margin-bottom: 16px;
		// 	text-align: center;
		// 	text-decoration: none;
		// 	transition: 0.2s ease-in-out;

		// 	&:hover {
		// 		background: $;
		// 		color: $white;
		// 		text-decoration: none;
		// 		transform: scale(1.05, 1.05);
		// 	}
		// }

		.buttons {
			padding-left: 15px;

			.btn-main {
				@include txt-font-20($white, $fw-semibold);
				padding: 0.3em 1.3em;

				&:hover {
					color: $title-main;
				}
			}
		}
	}
}
