.banner-contact {
	@include py-52;

	.title {
		@include mb-40;
		color: $white;
		font-weight: $fw-extrabold;
		margin-top: 0.8rem;
		text-transform: uppercase;
	}
}

.banner-menu {
	@include py-52;

	.title {
		@include mb-24;
		color: $white;
		font-weight: $fw-extrabold;
		margin-top: 0.8rem;
	}
}
