.bkg-img {
	background-color: $title-main;
	width: 100vw;

	&.top-section {
		background: url(#{$_image-path}/png/bkg-main.png) center / cover no-repeat;
		height: 546px;

		@include media-breakpoint-down(xs) {
			background: url(#{$_image-path}/png/bkg-main-xs.png) center / cover no-repeat;
			height: 193px;
		}
	}

	&.banner-contact {
		background: url(#{$_image-path}/png/bkg-services.png) center / cover no-repeat;
	}
	&.banner-menu {
		background: url(#{$_image-path}/png/bkg-menu.png) center / cover no-repeat;
	}
	&.section-form {
		background: url(#{$_image-path}/png/bkg-contact.png) center / cover no-repeat;
	}
}

.bkg-img.top-section {
	.container-fluid {
		height: 100%;
		.container {
			height: 100%; 
			text-align: center;
			display: flex;
			flex-direction: column;
			justify-content: center; 
			align-items: center;
			h1{
				font-size: 54px;
				color: $white;
			}
			.btn-main{
				margin-top: 2rem;
			}
		}
	}
}
