.section-catalog {
	.title {
		@include mb-40;
		text-transform: uppercase;
	}

	.catalog {
		padding: 1.5rem 0;

		.sub-title {
			color: $white;
			margin-bottom: 0;
			text-transform: uppercase;

			@include media-breakpoint-down(xs) {
				margin-right: 0.75rem;
			}
		}

		img {
			&.svg {
				height: 54px;
				position: absolute;

				&.carpas {
					left: 15rem;
				}
			}
		}

	}

	// Estilos agreagados
	.accordion-test {
		.accordion-item {
			border-bottom: 1px solid $light;

			[aria-expanded='true'] {
				border-bottom: 1px solid $blue;
			}
		}

		button {
			align-items: center;
			background: url(#{$_image-path}/png/bkg-catalog.png) center / cover no-repeat;
			border: 0;
			display: flex;
			justify-content: center;
			margin: 1rem 0;
			outline: none;
			padding: 1.5rem 102px 1.5rem 0;
			position: relative;
			width: 100%;

			@include media-breakpoint-down(xs) {
				justify-content: start;
				padding-right: 94px;
			}

			&:hover,
			&:focus {
				color: $blue;
				cursor: pointer;

				&::after {
					border: 1px solid $blue;
					color: $blue;
					cursor: pointer;
				}

			}

			.svg {
				display: inline-block;
				margin-right: 2rem;

				@include media-breakpoint-down(xs) {
					margin-left: 0.75rem;
					margin-right: 1rem;
				}

				&.carpas-domos {
					background: url(#{$_image-path}/svg/carpas-domos.svg) center / contain no-repeat;
					height: 54px;
					width: 54px;
				}

				&.mesas-sillas {
					background: url(#{$_image-path}/svg/mesas-sillas.svg) center / contain no-repeat;
					height: 47px;
					width: 61px;
				}

				&.escenarios {
					background: url(#{$_image-path}/svg/escenarios.svg) center / contain no-repeat;
					height: 54px;
					width: 61px;

					@include media-breakpoint-down(xs) { width: 99px; }
				}

				&.alimentos {
					background: url(#{$_image-path}/svg/alimentos.svg) center / contain no-repeat;
					height: 39px;
					width: 61px;
				}
			}

			.accordion-title {
				@include txt-font-40-sub($white, $fw-bold);
				margin-bottom: 0;
				text-transform: uppercase;

				@include media-breakpoint-down(xs) { margin-right: 0.75rem;	}
			}

			.icon {
				background-color: $title-main;
				border: 0;
				display: inline-block;
				height: 100%;
				position: absolute;
				right: 0;
				top: 0;
				width: 102px;

				@include media-breakpoint-down(xs) { width: 94px; }

				&::before {
					background: $white;
					bottom: 0;
					content: '';
					display: block;
					height: 2px;
					left: 0;
					margin: auto;
					position: absolute;
					right: 0;
					text-align: center;
					top: 0;
					width: 38px;
				}

				&::after {
					background: $white;
					bottom: 0;
					content: '';
					display: block;
					height: 38px;
					left: 0;
					margin: auto;
					position: absolute;
					right: 0;
					text-align: center;
					top: 0;
					width: 2px;
				}
			}
		}

		[aria-expanded='true'] {
			color: $blue;

			.icon {
				&::after {
					width: 0;
				}
			}

			+ .accordion-content {
				display: grid;
				grid-column-gap: 0.417rem;
				grid-row-gap: 0.4rem;
				max-height: 100%;
				opacity: 1;
				overflow: hidden;
				padding-bottom: 2rem;
				transition: all 200ms linear;
				will-change: opacity, max-height;

				&.carpas-domos {
					grid-template-columns: repeat(20, 1fr);
					grid-template-rows: 283px 283px 251px;

					@include media-breakpoint-down(xs) {
						grid-template-columns: repeat(10, 1fr);
						grid-template-rows: 283px 283px 283px 283px 251px 251px;
					}

					.img-1 {
						background: url(#{$_image-path}/png/carpas-1.png) center / cover no-repeat;	grid-column-end: span 8;
						@include media-breakpoint-down(xs) { grid-column-end: span 10; }
					}

					.img-2 {
						background: url(#{$_image-path}/png/carpas-2.png) center / cover no-repeat;	grid-column-end: span 6;
						@include media-breakpoint-down(xs) { grid-column-end: span 5;	}
					}

					.img-3 {
						background: url(#{$_image-path}/png/carpas-3.png) center / cover no-repeat;	grid-column-end: span 6;
						@include media-breakpoint-down(xs) { grid-column-end: span 5;	}
					}

					.img-4 {
						background: url(#{$_image-path}/png/carpas-4.png) center / cover no-repeat;	grid-column-end: span 6;
						@include media-breakpoint-down(xs) { grid-column-end: span 5;	}
					}

					.img-5 {
						background: url(#{$_image-path}/png/carpas-5.png) center / cover no-repeat;	grid-column-end: span 8;
						@include media-breakpoint-down(xs) { grid-column-end: span 10; }
					}

					.img-6 {
						background: url(#{$_image-path}/png/carpas-6.png) center / cover no-repeat;	grid-column-end: span 6;
						@include media-breakpoint-down(xs) { grid-column-end: span 5;	grid-row-start: 3; }
					}

					.img-7 { background: url(#{$_image-path}/png/carpas-7.png) center / cover no-repeat;	grid-column-end: span 5; }
					.img-8 { background: url(#{$_image-path}/png/carpas-8.png) center / cover no-repeat;	grid-column-end: span 5; }
					.img-9 { background: url(#{$_image-path}/png/carpas-9.png) center / cover no-repeat;	grid-column-end: span 5; }
					.img-10 {	background: url(#{$_image-path}/png/carpas-10.png) center / cover no-repeat;	grid-column-end: span 5; }
				}

				&.mesas-sillas {
					grid-template-columns: repeat(20, 1fr);
					grid-template-rows: 251px 300px 300px;

					@include media-breakpoint-down(xs) {
						grid-template-columns: repeat(10, 1fr);
						grid-template-rows: 251px 251px 300px 300px 300px 300px;
					}

					.img-1 { background: url(#{$_image-path}/png/mesas-1.png) center / cover no-repeat;	grid-column-end: span 5; }
					.img-2 { background: url(#{$_image-path}/png/mesas-2.png) center / cover no-repeat;	grid-column-end: span 5; }
					.img-3 { background: url(#{$_image-path}/png/mesas-3.png) center / cover no-repeat;	grid-column-end: span 5; }
					.img-4 { background: url(#{$_image-path}/png/mesas-4.png) center / cover no-repeat;	grid-column-end: span 5; }
					.img-5 { background: url(#{$_image-path}/png/mesas-5.png) center / cover no-repeat;	grid-column-end: span 5; grid-row-end: span 2; }

					.img-6 {
						background: url(#{$_image-path}/png/mesas-6.png) center / cover no-repeat; grid-column-end: span 6;
						@include media-breakpoint-down(xs) { grid-column-end: span 5;	}
					}

					.img-7 {
						background: url(#{$_image-path}/png/mesas-7.png) center / cover no-repeat; grid-column-end: span 9;
						@include media-breakpoint-down(xs) { grid-column-end: span 10; grid-row-start: 5; }
					}

					.img-8 {
						background: url(#{$_image-path}/png/mesas-8.png) center / cover no-repeat; grid-column-end: span 6;
						@include media-breakpoint-down(xs) { grid-column-end: span 5; grid-column-start: 6; grid-row-start: 4; }
					}

					.img-9 {
						background: url(#{$_image-path}/png/mesas-9.png) center / cover no-repeat; grid-column-end: span 9;
						@include media-breakpoint-down(xs) { grid-column-end: span 10; grid-row-start: 6; }
					}
				}

				&.pistas-escenarios {
					grid-template-columns: repeat(20, 1fr);
					grid-template-rows: 251px 292px 292px;

					@include media-breakpoint-down(xs) {
						grid-template-columns: repeat(10, 1fr);
						grid-template-rows: 251px 251px 292px 292px 292px 292px;
					}

					.img-1 { background: url(#{$_image-path}/png/pistas-1.png) center / cover no-repeat; grid-column-end: span 5; }
					.img-2 { background: url(#{$_image-path}/png/pistas-2.png) center / cover no-repeat; grid-column-end: span 5; }
					.img-3 { background: url(#{$_image-path}/png/pistas-3.png) center / cover no-repeat; grid-column-end: span 5; }
					.img-4 { background: url(#{$_image-path}/png/pistas-4.png) center / cover no-repeat; grid-column-end: span 5; }
					.img-5 { background: url(#{$_image-path}/png/pistas-5.png) center / cover no-repeat; grid-column-end: span 5; grid-row-end: span 2; }

					.img-6 {
						background: url(#{$_image-path}/png/pistas-6.png) center / cover no-repeat;	grid-column-end: span 6;

						@include media-breakpoint-down(xs) { grid-column-end: span 5; }
					}

					.img-7 {
						background: url(#{$_image-path}/png/pistas-7.png) center / cover no-repeat;	grid-column-end: span 9;
						@include media-breakpoint-down(xs) { grid-column-end: span 10; grid-row-start: 5; }
					}

					.img-8 {
						background: url(#{$_image-path}/png/pistas-8.png) center / cover no-repeat;	grid-column-end: span 9;
						@include media-breakpoint-down(xs) { grid-column-end: span 10; grid-row-start: 6; }
					}

					.img-9 {
						background: url(#{$_image-path}/png/pistas-9.png) center / cover no-repeat;	grid-column-end: span 6;
						@include media-breakpoint-down(xs) { grid-column-end: span 5; grid-column-start: 6; grid-row-start: 4; }
					}
				}

				&.alimentos {
					grid-template-columns: repeat(20, 1fr);
					grid-template-rows: 251px 300px 300px;

					@include media-breakpoint-down(xs) {
						grid-template-columns: repeat(10, 1fr);
						grid-template-rows: 251px 251px 300px 300px 300px 300px;
					}

					.img-1 { background: url(#{$_image-path}/png/alimentos-1.png) center / cover no-repeat; grid-column-end: span 5; }
					.img-2 { background: url(#{$_image-path}/png/alimentos-2.png) center / cover no-repeat; grid-column-end: span 5; }
					.img-3 { background: url(#{$_image-path}/png/alimentos-3.png) center / cover no-repeat; grid-column-end: span 5; }
					.img-4 { background: url(#{$_image-path}/png/alimentos-4.png) center / cover no-repeat; grid-column-end: span 5; }
					.img-5 { background: url(#{$_image-path}/png/alimentos-5.png) center / cover no-repeat; grid-column-end: span 5; grid-row-end: span 2; }

					.img-6 {
						background: url(#{$_image-path}/png/alimentos-6.png) center / cover no-repeat; grid-column-end: span 6;
						@include media-breakpoint-down(xs) { grid-column-end: span 5;	}
					}

					.img-7 {
						background: url(#{$_image-path}/png/alimentos-7.png) center / cover no-repeat; grid-column-end: span 9;
						@include media-breakpoint-down(xs) { grid-column-end: span 10; grid-row-start: 5; }
					}

					.img-8 {
						background: url(#{$_image-path}/png/alimentos-8.png) center / cover no-repeat; grid-column-end: span 6;
						@include media-breakpoint-down(xs) { grid-column-end: span 5; grid-column-start: 6; grid-row-start: 4; }
					}

					.img-9 {
						background: url(#{$_image-path}/png/alimentos-9.png) center / cover no-repeat; grid-column-end: span 9;
						@include media-breakpoint-down(xs) { grid-column-end: span 10; grid-row-start: 6; }
					}
				}
			}
		}

		.accordion-content {
			max-height: 0;
			opacity: 0;
			overflow: hidden;
			transition: opacity 200ms linear, max-height 200ms linear;
			will-change: opacity, max-height;
		}
	}
	// Terminan estilos agregados

	.wrapper-accordions {
		@include mb-90;
	}
	/* Style the buttons that are used to open and close the accordion panel */
	.accordion {
		align-items: center;
		cursor: pointer;
		display: flex;
		margin: 1rem 0;
		transition: all 0.4s ease-in-out;

		&:hover { background-color: $light; }

		.wrapper {
			align-items: center;
			display: flex;
			justify-content: center;
			min-height: 102px;
			width: 90%;

			@include media-breakpoint-down(xs) {
				justify-content: start;
			}
		}

		.plus {
			background-color: $title-main;
			height: 102px;
			width: 102px;

			.icon {
				height: 22px;
				position: relative;
				right: 0;
				top: 18px;
				width: 22px;

				&::before {
					background: $white;
					content: '';
					display: block;
					height: 2px;
					left: 5px;
					position: absolute;
					top: 9px;
					width: 10px;
				}

				&::after {
					background: $white;
					content: '';
					display: block;
					height: 10px;
					left: 9px;
					position: absolute;
					top: 5px;
					width: 2px;
				}
			}
			// background: linear-gradient($title-main, $title-main) top left, linear-gradient($title-main, $title-main) top right, linear-gradient($title-main, $title-main) bottom left, linear-gradient($title-main, $title-main) bottom right;
			// background-repeat: no-repeat;
			// background-size: calc(50% - 1px) calc(50% - 1px); /*thickness = 2px (2*1px) */
			// border: 30px solid $title-main; /*length = 30px (50px - 2x10px) */
			// box-sizing: border-box;
			// display: inline-block;
			// height: 102px;
			// margin-left: 0.2rem;
			// width: 121px;

			// @include media-breakpoint-down(xs) {
			// 	border-bottom-width: 15px;
			// 	border-left-width: 5px;
			// 	border-right-width: 5px;
			// 	border-top-width: 15px;
			// 	width: 61px;
			// }
		}

		.svg {
			margin-right: 2rem;

			@include media-breakpoint-down(xs) {
				margin-left: 0.75rem;
				margin-right: 0.5rem;
			}

			&.carpas-domos {
				background: url(#{$_image-path}/svg/carpas-domos.svg) center / contain no-repeat;
				height: 54px;
				width: 54px;
			}

			&.mesas-sillas {
				background: url(#{$_image-path}/svg/mesas-sillas.svg) center / contain no-repeat;
				height: 47px;
				width: 61px;
			}

			&.escenarios {
				background: url(#{$_image-path}/svg/escenarios.svg) center / contain no-repeat;
				height: 54px;
				width: 61px;

				@include media-breakpoint-down(xs) {
					width: 99px;
				}
			}

			&.alimentos {
				background: url(#{$_image-path}/svg/alimentos.svg) center / contain no-repeat;
				height: 39px;
				width: 61px;
			}
		}

	}

	/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
	.active {
		background-color: $light;

		// .plus {
		// 	background-size: calc(50% - 1px); /*thickness = 2px (2*1px) */
		// 	transform: rotate(90deg);
		// }
	}

	/* Style the accordion panel. Note: hidden by default */
	.panel {
		background-color: $white;
		display: none;
		grid-column-gap: 0.417rem;
		grid-row-gap: 0.4rem;
		overflow: hidden;
		padding-bottom: 2rem;

		&.carpas-domos {
			grid-template-columns: repeat(20, 1fr);
			grid-template-rows: 283px 283px 251px;

			@include media-breakpoint-down(xs) {
				grid-template-columns: repeat(10, 1fr);
				grid-template-rows: 283px 283px 283px 283px 251px 251px;
			}

			.img-1 {
				background: url(#{$_image-path}/png/carpas-1.png) center / cover no-repeat;
				grid-column-end: span 8;

				@include media-breakpoint-down(xs) {
					grid-column-end: span 10;
				}
			}

			.img-2 {
				background: url(#{$_image-path}/png/carpas-2.png) center / cover no-repeat;
				grid-column-end: span 6;

				@include media-breakpoint-down(xs) {
					grid-column-end: span 5;
				}
			}

			.img-3 {
				background: url(#{$_image-path}/png/carpas-3.png) center / cover no-repeat;
				grid-column-end: span 6;

				@include media-breakpoint-down(xs) {
					grid-column-end: span 5;
				}
			}

			.img-4 {
				background: url(#{$_image-path}/png/carpas-4.png) center / cover no-repeat;
				grid-column-end: span 6;

				@include media-breakpoint-down(xs) {
					grid-column-end: span 5;
				}
			}

			.img-5 {
				background: url(#{$_image-path}/png/carpas-5.png) center / cover no-repeat;
				grid-column-end: span 8;

				@include media-breakpoint-down(xs) {
					grid-column-end: span 10;
				}
			}

			.img-6 {
				background: url(#{$_image-path}/png/carpas-6.png) center / cover no-repeat;
				grid-column-end: span 6;

				@include media-breakpoint-down(xs) {
					grid-column-end: span 5;
					grid-row-start: 3;
				}
			}

			.img-7 {
				background: url(#{$_image-path}/png/carpas-7.png) center / cover no-repeat;
				grid-column-end: span 5;
			}

			.img-8 {
				background: url(#{$_image-path}/png/carpas-8.png) center / cover no-repeat;
				grid-column-end: span 5;
			}

			.img-9 {
				background: url(#{$_image-path}/png/carpas-9.png) center / cover no-repeat;
				grid-column-end: span 5;
			}

			.img-10 {
				background: url(#{$_image-path}/png/carpas-10.png) center / cover no-repeat;
				grid-column-end: span 5;
			}

		}

		&.mesas-sillas {
			grid-template-columns: repeat(20, 1fr);
			grid-template-rows: 251px 300px 300px;

			@include media-breakpoint-down(xs) {
				grid-template-columns: repeat(10, 1fr);
				grid-template-rows: 251px 251px 300px 300px 300px 300px;
			}

			.img-1 {
				background: url(#{$_image-path}/png/mesas-1.png) center / cover no-repeat;
				grid-column-end: span 5;
			}

			.img-2 {
				background: url(#{$_image-path}/png/mesas-2.png) center / cover no-repeat;
				grid-column-end: span 5;
			}

			.img-3 {
				background: url(#{$_image-path}/png/mesas-3.png) center / cover no-repeat;
				grid-column-end: span 5;
			}

			.img-4 {
				background: url(#{$_image-path}/png/mesas-4.png) center / cover no-repeat;
				grid-column-end: span 5;
			}

			.img-5 {
				background: url(#{$_image-path}/png/mesas-5.png) center / cover no-repeat;
				grid-column-end: span 5;
				grid-row-end: span 2;
			}

			.img-6 {
				background: url(#{$_image-path}/png/mesas-6.png) center / cover no-repeat;
				grid-column-end: span 6;

				@include media-breakpoint-down(xs) {
					grid-column-end: span 5;
				}
			}

			.img-7 {
				background: url(#{$_image-path}/png/mesas-7.png) center / cover no-repeat;
				grid-column-end: span 9;

				@include media-breakpoint-down(xs) {
					grid-column-end: span 10;
					grid-row-start: 5;
				}
			}

			.img-8 {
				background: url(#{$_image-path}/png/mesas-8.png) center / cover no-repeat;
				grid-column-end: span 6;

				@include media-breakpoint-down(xs) {
					grid-column-end: span 5;
					grid-column-start: 6;
					grid-row-start: 4;
				}
			}

			.img-9 {
				background: url(#{$_image-path}/png/mesas-9.png) center / cover no-repeat;
				grid-column-end: span 9;

				@include media-breakpoint-down(xs) {
					grid-column-end: span 10;
					grid-row-start: 6;
				}
			}

		}

		&.pistas-escenarios {
			grid-template-columns: repeat(20, 1fr);
			grid-template-rows: 251px 292px 292px;

			@include media-breakpoint-down(xs) {
				grid-template-columns: repeat(10, 1fr);
				grid-template-rows: 251px 251px 292px 292px 292px 292px;
			}

			.img-1 {
				background: url(#{$_image-path}/png/pistas-1.png) center / cover no-repeat;
				grid-column-end: span 5;
			}

			.img-2 {
				background: url(#{$_image-path}/png/pistas-2.png) center / cover no-repeat;
				grid-column-end: span 5;
			}

			.img-3 {
				background: url(#{$_image-path}/png/pistas-3.png) center / cover no-repeat;
				grid-column-end: span 5;
			}

			.img-4 {
				background: url(#{$_image-path}/png/pistas-4.png) center / cover no-repeat;
				grid-column-end: span 5;
			}

			.img-5 {
				background: url(#{$_image-path}/png/pistas-5.png) center / cover no-repeat;
				grid-column-end: span 5;
				grid-row-end: span 2;
			}

			.img-6 {
				background: url(#{$_image-path}/png/pistas-6.png) center / cover no-repeat;
				grid-column-end: span 6;

				@include media-breakpoint-down(xs) {
					grid-column-end: span 5;
				}
			}

			.img-7 {
				background: url(#{$_image-path}/png/pistas-7.png) center / cover no-repeat;
				grid-column-end: span 9;

				@include media-breakpoint-down(xs) {
					grid-column-end: span 10;
					grid-row-start: 5;
				}
			}

			.img-8 {
				background: url(#{$_image-path}/png/pistas-8.png) center / cover no-repeat;
				grid-column-end: span 9;

				@include media-breakpoint-down(xs) {
					grid-column-end: span 10;
					grid-row-start: 6;
				}
			}

			.img-9 {
				background: url(#{$_image-path}/png/pistas-9.png) center / cover no-repeat;
				grid-column-end: span 6;

				@include media-breakpoint-down(xs) {
					grid-column-end: span 5;
					grid-column-start: 6;
					grid-row-start: 4;
				}
			}

		}

		&.alimentos {
			grid-template-columns: repeat(20, 1fr);
			grid-template-rows: 251px 300px 300px;

			@include media-breakpoint-down(xs) {
				grid-template-columns: repeat(10, 1fr);
				grid-template-rows: 251px 251px 300px 300px 300px 300px;
			}

			.img-1 {
				background: url(#{$_image-path}/png/alimentos-1.png) center / cover no-repeat;
				grid-column-end: span 5;
			}

			.img-2 {
				background: url(#{$_image-path}/png/alimentos-2.png) center / cover no-repeat;
				grid-column-end: span 5;
			}

			.img-3 {
				background: url(#{$_image-path}/png/alimentos-3.png) center / cover no-repeat;
				grid-column-end: span 5;
			}

			.img-4 {
				background: url(#{$_image-path}/png/alimentos-4.png) center / cover no-repeat;
				grid-column-end: span 5;
			}

			.img-5 {
				background: url(#{$_image-path}/png/alimentos-5.png) center / cover no-repeat;
				grid-column-end: span 5;
				grid-row-end: span 2;
			}

			.img-6 {
				background: url(#{$_image-path}/png/alimentos-6.png) center / cover no-repeat;
				grid-column-end: span 6;

				@include media-breakpoint-down(xs) {
					grid-column-end: span 5;
				}
			}

			.img-7 {
				background: url(#{$_image-path}/png/alimentos-7.png) center / cover no-repeat;
				grid-column-end: span 9;

				@include media-breakpoint-down(xs) {
					grid-column-end: span 10;
					grid-row-start: 5;
				}
			}

			.img-8 {
				background: url(#{$_image-path}/png/alimentos-8.png) center / cover no-repeat;
				grid-column-end: span 6;

				@include media-breakpoint-down(xs) {
					grid-column-end: span 5;
					grid-column-start: 6;
					grid-row-start: 4;
				}
			}

			.img-9 {
				background: url(#{$_image-path}/png/alimentos-9.png) center / cover no-repeat;
				grid-column-end: span 9;

				@include media-breakpoint-down(xs) {
					grid-column-end: span 10;
					grid-row-start: 6;
				}
			}

		}
	}

}
