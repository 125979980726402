/*
* COLORS
*/

// WhiteScale
$white:    #fff;

// DarkScale
$black:        #000;
$black-lat:    #111;
$p-main:       #333;

$secondary:    #ccc;
$light:        #f8f9fa;


// BlueScale
$bkg-main:     #000a35;
$ah-menu:      #1050fa;// color button

$title-main:   #40a6dd;

$pf-main:       #42738d;// color button

// RGBA
$glass-bkg:    rgba(255, 255, 255, 0.6);
$glass-border: rgba(255, 255, 255, 0.18);
$glass-shadow: rgba(255, 255, 255, 0.70);


/*
* FONTS
*/
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;500;600;700;800;900&display=swap');
$fm-lato: 'Raleway', sans-serif;

$fw-light:     300;
$fw-medium:    500;
$fw-semibold:  600;
$fw-bold:      700;
$fw-extrabold: 800;
$fw-black:     900;

$fs-12: 0.75rem;
$fs-14: 0.875rem;
$fs-15: 0.9375rem;
$fs-16: 1rem;
$fs-18: 1.125rem;
$fs-20: 1.25rem;
$fs-22: 1.375rem;
$fs-24: 1.5rem;
$fs-26: 1.625rem;
$fs-28: 1.75rem;
$fs-30: 1.875rem;
$fs-32: 2rem;
$fs-34: 2.125rem;
$fs-36: 2.25rem;
$fs-38: 2.375rem;
$fs-40: 2.5rem;
$fs-42: 2.625rem;
$fs-44: 2.75rem;
$fs-46: 2.875rem;
$fs-48: 3rem;
$fs-50: 3.125rem;
$fs-52: 3.25rem;
$fs-54: 3.375rem;
$fs-56: 3.5rem;
$fs-58: 3.625rem;
$fs-60: 3.75rem;
$fs-62: 3.875rem;
$fs-64: 4rem;
$fs-66: 4.125rem;
$fs-68: 4.25rem;
$fs-70: 4.375rem;
$fs-72: 4.5rem;
$fs-74: 4.625rem;
$fs-76: 4.75rem;
$fs-78: 4.875rem;
$fs-80: 5rem;
$fs-82: 5.125rem;
$fs-84: 5.25rem;
$fs-86: 5.375rem;
$fs-88: 5.5rem;
$fs-90: 5.625rem;
$fs-92: 5.75rem;
$fs-94: 5.875rem;
$fs-96: 6rem;
$fs-98: 6.125rem;
$fs-100: 6.25rem;


/*
*	MISC
*/

$_image-path : '../images';


/*
*	LAYOUT
*/

$_header-mobile-breakpoint : 'lg';

$_menu-mobile-breakpoint 		: 'md';
