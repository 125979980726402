// Margins X
// Margins Y
// @mixin my-72 {
// 	margin-bottom: $fs-72; margin-top: $fs-72;

// 	@include media-breakpoint-down(md) { margin-bottom: $fs-60; margin-top: $fs-60;	}
// 	@include media-breakpoint-down(xs) { margin-bottom: $fs-48; margin-top: $fs-48;	}
// }

// @mixin my-52 {
// 	margin-bottom: $fs-52; margin-top: $fs-52;

// 	@include media-breakpoint-down(md) { margin-bottom: $fs-50; margin-top: $fs-50;	}
// 	@include media-breakpoint-down(xs) { margin-bottom: $fs-48; margin-top: $fs-48;	}
// }

// Margins Bottom
@mixin mb-100 {
	margin-bottom: $fs-100;

	@include media-breakpoint-down(md) { margin-bottom: $fs-90;	}
	@include media-breakpoint-down(sm) { margin-bottom: $fs-80;	}
	@include media-breakpoint-down(xs) { margin-bottom: $fs-70;	}
}

@mixin mb-90 {
	margin-bottom: $fs-90;

	@include media-breakpoint-down(md) { margin-bottom: $fs-80;	}
	@include media-breakpoint-down(sm) { margin-bottom: $fs-64;	}
	@include media-breakpoint-down(xs) { margin-bottom: $fs-50;	}
}

@mixin mb-84 {
	margin-bottom: $fs-84;

	@include media-breakpoint-down(md) { margin-bottom: $fs-74;	}
	@include media-breakpoint-down(sm) { margin-bottom: $fs-58;	}
	@include media-breakpoint-down(xs) { margin-bottom: $fs-46;	}
}

@mixin mb-72 {
	margin-bottom: $fs-72;

	@include media-breakpoint-down(md) { margin-bottom: $fs-68;	}
	@include media-breakpoint-down(sm) { margin-bottom: $fs-54;	}
	@include media-breakpoint-down(xs) { margin-bottom: $fs-42;	}
}

@mixin mb-60 {
	margin-bottom: $fs-60;

	@include media-breakpoint-down(md) { margin-bottom: $fs-50;	}
	@include media-breakpoint-down(xs) { margin-bottom: $fs-40;	}
}

@mixin mb-52 {
	margin-bottom: $fs-52;

	@include media-breakpoint-down(md) { margin-bottom: $fs-46;	}
	@include media-breakpoint-down(xs) { margin-bottom: $fs-40;	}
}

@mixin mb-40 {
	margin-bottom: $fs-40;

	@include media-breakpoint-down(md) { margin-bottom: $fs-34;	}
	@include media-breakpoint-down(xs) { margin-bottom: $fs-28;	}
}

@mixin mb-36 {
	margin-bottom: $fs-36;

	@include media-breakpoint-down(md) { margin-bottom: $fs-32;	}
	@include media-breakpoint-down(xs) { margin-bottom: $fs-26;	}
}

@mixin mb-32 {
	margin-bottom: $fs-32;

	@include media-breakpoint-down(md) { margin-bottom: $fs-28;	}
	@include media-breakpoint-down(xs) { margin-bottom: $fs-24;	}
}

@mixin mb-24 {
	margin-bottom: $fs-24;

	@include media-breakpoint-down(md) { margin-bottom: $fs-22;	}
	@include media-breakpoint-down(xs) { margin-bottom: $fs-20;	}
}

@mixin mb-16 {
	margin-bottom: $fs-16;

	@include media-breakpoint-down(xs) { margin-bottom: $fs-14;	}
}

@mixin mtn-8{
	margin-top: -8rem;
}

// Margins Top
// Margins Left
// Margins Right
